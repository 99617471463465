<template>
  <div>
      <v-data-table dense :headers="headerLocations" disable-pagination :items="locations" hide-default-footer v-if="responsive == 'PC'" class="px-5 pa-5">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" v-show="locations.length > 0">
              <td>
                <v-icon v-if="ip == item.ip" size="18" color="success">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="ip != item.ip" size="18" color="warning">mdi-information</v-icon>
              </td>
              <td>{{ item.ip }}</td>
              <td>{{ item.city + ", " + item.region + ", " + item.country }}</td>
              <td>{{ formatDate(item.timestamp) }}</td>
            </tr>
            <tr v-if="locationLoading">
              <td colspan="3">
                <v-text-field loading disabled></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
        <div class="text-right mt-2 fs-12">
          {{ $t('user.location.message') }}
        </div>
        <div v-show="locations.length == 0" class="text-center">
          {{ $t('user.location.noData') }}
        </div>
      </template>
      </v-data-table>
       <v-container class="flex-b pl-0 mt-6 pr-0 mb-12 pb-12"  v-if="responsive == 'mobile'">
        <v-card flat class="px-5 pa-5" v-for="item in locations" :key="item.id">
          <v-row no-gutters>  
            <v-col cols="12" no-gutters>
              <span>{{ $t('user.location.ip') }}</span>
              <span class="float-right">
                <span>{{ item.ip }}</span>
                <v-icon v-if="ip == item.ip" size="14" color="success" class="ml-2">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="ip != item.ip" size="14" color="warning" class="ml-2">mdi-information</v-icon>
              </span>
            </v-col>
            <v-col cols="12" no-gutters class="my-0 py-0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" no-gutters>
              <span>{{ $t('user.location.address') }}</span>
              <span class="float-right">{{ item.city + ", " + item.region + ", " + item.country }}</span>
            </v-col>
            <v-col cols="12" no-gutters class="my-0 py-0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" no-gutters>
              <span>{{ $t('user.location.time') }}</span>
              <span class="float-right">{{ formatDate(item.timestamp) }}</span>
            </v-col>         
          </v-row>
        </v-card>
  
        <v-row class="px-5 pa-5">
          <v-col cols="12" no-gutters>
            <span class="float-right" v-show="locations.length > 0">{{ $t('user.location.message') }}</span>
            <span class="float-right" v-show="locations.length == 0">{{ $t('user.location.noData') }}</span>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        locations:[],
        locationLoading:false,
        headerLocations:[
           { text: this.$t('user.location.security'), sortable: false, class:'fs-12'},
           { text: this.$t('user.location.ip'), sortable: false, class:'fs-12'},
           { text: this.$t('user.location.address'), sortable: false, class:'fs-12'},
           { text: this.$t('user.location.time'), sortable: false, class:'fs-12'},
        ]
      }
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      
    },
    mounted() {
      this.getLocations();
    },
    computed:{
      ...mapGetters(['time', 'responsive', 'darkMode', 'ip']),
    },
    methods: {
      // 获取用户的登录记录
      getLocations(){
        this.locationLoading = true;
        let api = config.baseUrl + "/user/private/getLocations?pageSize=20";
        this.$http.get(api).then(res =>{
            let data = res.data;
            if(data.code == 200){
                this.locations = data.result.locations;
                this.locationLoading = false;
            }else{
              this.locationLoading = false;
            }
        });
      },
      // 格式化时间
      formatDate(timestamp){
        if('UTCTime' == this.time){
          return formatUTCTime(timestamp);
        }else{
          return formatTime(timestamp);
        }
      },
    }
  }

</script>

<style>

</style>
