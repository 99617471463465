<template>
    <div>
      <v-card flat tile v-if="responsive == 'PC'">
        <v-card-title>
          <v-list-item-avatar color="primary" class="ml-0" size="60">
            <img v-if="user != null && user.avatar != null" :src="user.avatar" />
            <span v-else class="white--text">
              {{ user.username != null ? user.username.substring(0, 1).toLocaleUpperCase() : '' }}
            </span>
          </v-list-item-avatar>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-icon size="20" class="mr-2">mdi-account-star</v-icon>
              {{ $t('user.info.uid') }}: 
            </v-col>
            <v-col cols="12" sm="6" md="5">{{ user.id }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-icon size="20" class="mr-2">mdi-account-key</v-icon>
              {{ $t('user.info.username') }}: 
            </v-col>
            <v-col cols="12" sm="6" md="5">{{ '@' + user.username }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-icon size="20" class="mr-2">mdi-email-outline</v-icon>
              {{ $t('user.info.email') }}: 
            </v-col>
            <v-col cols="12" sm="6" md="5">{{ user.email }}</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-icon size="20" class="mr-2">mdi-lan</v-icon>
              {{ $t('user.info.role') }}: 
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <span v-role="user.role"></span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-icon size="20" class="mr-2">mdi-clock-outline</v-icon>
              {{ $t('user.info.timeZone') }}: 
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <span v-if="user.timeZone == 'UTC'">{{ $t('menu.utcTime') }}</span>
              <span v-if="user.timeZone == 'LT'">{{ $t('menu.localTime') }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-icon size="20" class="mr-2">mdi-google-translate</v-icon>
              {{ $t('user.info.language') }}: 
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <span v-if="user.locale == 'en-US'">English</span>
              <span v-if="user.locale == 'zh-CN'">中文（简体）</span>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
    <v-card flat tile v-if="responsive == 'PC'" class="mt-5">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12" class="align-sm-center align-content-center">
            <v-row dense>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="34">mdi-lock-outline</v-icon>
              </v-col>
              <v-col md="8" lg="10">
                <span class="fw-bold fs-16">{{ $t('user.info.loginPassword') }}</span>
                <div class="">{{ $t('user.info.loginPasswordMessage') }}</div>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="18" color="success">mdi-checkbox-marked-circle</v-icon>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <span class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" href="https://iob.community/#/user/settings" target="_black">{{ $t('common.change') }}</v-btn>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row dense>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="34">mdi-email-outline</v-icon>
              </v-col>
              <v-col md="8" lg="10">
                <span class="fw-bold fs-16">{{ $t('user.info.emailVerification') }}</span>
                <div class="">{{ $t('user.info.emailVerificationMessage') }}</div>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="18" color="success">mdi-checkbox-marked-circle</v-icon>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <span class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" href="https://iob.community/#/user/settings" target="_black">{{ $t('common.change') }}</v-btn>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row dense>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="34">mdi-security</v-icon>
              </v-col>
              <v-col md="8" lg="10">
                <span class="fw-bold fs-16">{{ $t('user.info.googleAuthenticator') }}</span>
                <div class="">{{ $t('user.info.googleAuthenticatorMessage') }}</div>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon v-if="user.openAuth" size="18" color="success">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="!user.openAuth" size="18" color="warning">mdi-information</v-icon>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <!-- 禁用的时候 -->
                <span v-if="!user.openAuth" class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" @click="enableGA">{{ $t('user.info.enable') }}</v-btn>
                </span>
                <!-- 启用的时候 -->
                <span v-if="user.openAuth" class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" @click="closeGA">{{ $t('common.close') }}</v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-container v-if="responsive == 'mobile'" class="flex-b pl-0 mt-6 pr-0 mb-5 pb-5">
      <v-card flat class="px-4 pa-4">   
        <v-row no-gutters>  
            <v-col cols="12" xs="12">
              <span>
                <v-avatar>
                <img v-if="user != null && user.avatar != null" :src="user.avatar" />
                  <span v-else>
                    {{ user.username != null ? user.username.substring(0, 1).toLocaleUpperCase() : '' }}
                  </span>
              </v-avatar>
              </span> 
              <span class="ml-5 fs-20">{{ '@' + user.username }}</span>    
          </v-col>
          <v-col cols="12" no-gutters class="my-0 py-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" no-gutters>
            <span>
              <v-icon size="20" class="mr-2">mdi-account-star</v-icon>
                  {{ $t('user.info.uid') }}: 
              </span>
            <span class="float-right">{{ user.id }}</span>
          </v-col>
           <v-col cols="12" no-gutters class="my-0 py-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" no-gutters>
            <span>
              <v-icon size="20" class="mr-2">mdi-email-outline</v-icon>
              {{ $t('user.info.email') }}:
            </span>
            <span class="float-right">{{ user.email }}</span>
          </v-col>
          <v-col cols="12" no-gutters class="my-0 py-0">
            <v-divider></v-divider>
          </v-col>
           <v-col cols="12" no-gutters>
            <span>
              <v-icon size="20" class="mr-2">mdi-lan</v-icon>
                {{ $t('user.info.role') }}: 
            </span>
            <span class="float-right">
              <span v-role="user.role"></span>
            </span>
          </v-col>
          <v-col cols="12" no-gutters class="my-0 py-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" no-gutters>
            <span>
              <v-icon size="20" class="mr-2">mdi-clock-outline</v-icon>
              {{ $t('user.info.timeZone') }}: 
            </span>
            <span class="float-right">
              <span v-if="user.timeZone == 'UTC'">{{ $t('menu.utcTime') }}</span>
              <span v-if="user.timeZone == 'LT'">{{ $t('menu.localTime') }}</span>
            </span>
          </v-col>
          <v-col cols="12" no-gutters class="my-0 py-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" no-gutters>
            <span>
              <v-icon size="20" class="mr-2">mdi-google-translate</v-icon>
              {{ $t('user.info.language') }}: 
            </span>
            <span class="float-right">
              <span v-if="user.locale == 'en-US'">English</span>
              <span v-if="user.locale == 'zh-CN'">中文（简体）</span>
            </span>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat tile v-if="responsive == 'mobile'" class="mt-5">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12" class="align-sm-center align-content-center">
            <!-- 登录密码 -->
            <v-row dense>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="34">mdi-lock-outline</v-icon>
              </v-col>
              <v-col md="8" lg="10">
                <span class="fw-bold fs-16">{{ $t('user.info.loginPassword') }}</span>
                <div class="">{{ $t('user.info.loginPasswordMessage') }}</div>
              </v-col>
              <v-col cols="auto" class="mt-3">
                <v-icon size="18" color="success">mdi-checkbox-marked-circle</v-icon>
              </v-col>
              <v-col cols="auto" class="mt-3">
                <span class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" href="https://iob.community/#/user/settings" target="_black">{{ $t('common.change') }}</v-btn>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- 邮箱验证 -->
            <v-row dense>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="34">mdi-email-outline</v-icon>
              </v-col>
              <v-col md="8" lg="10">
                <span class="fw-bold fs-16">{{ $t('user.info.emailVerification') }}</span>
                <div class="">{{ $t('user.info.emailVerificationMessage') }}</div>
              </v-col>
              <v-col cols="auto" class="mt-3">
                <v-icon size="18" color="success">mdi-checkbox-marked-circle</v-icon>
              </v-col>
              <v-col cols="auto">
                <span class="float-right mt-3">
                  <v-btn text x-small class="text-none lineblue--text" href="https://iob.community/#/user/settings" target="_black">{{ $t('common.change') }}</v-btn>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- 谷歌认证 -->
            <v-row dense>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon size="34">mdi-security</v-icon>
              </v-col>
              <v-col md="8" lg="10">
                <span class="fw-bold fs-16">{{ $t('user.info.googleAuthenticator') }}</span>
                <div class="">{{ $t('user.info.googleAuthenticatorMessage') }}</div>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <v-icon v-if="user.openAuth" size="18" color="success">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="!user.openAuth" size="18" color="warning">mdi-information</v-icon>
              </v-col>
              <v-col cols="auto" class="align-self-sm-center">
                <span v-if="user.openAuth" class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" @click="">{{ $t('common.change') }}</v-btn>
                  <v-btn text x-small class="text-none lineblue--text" @click="">{{ $t('common.close') }}</v-btn>
                </span>
                <span v-if="!user.openAuth" class="float-right">
                  <v-btn text x-small class="text-none lineblue--text" @click="enableGA">{{ $t('user.info.enable') }}</v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import Bus from '@/components/bus/Bus';
  export default {
    data() {
      return {
        
      }
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      
    },
    mounted() {
      this.getMe();
      // 监听当前价的事件
      Bus.$on('emitGetMe', data => {
        this.getMe();
      })
    },
    computed:{
      ...mapGetters(['user','time', 'responsive','darkMode']),
    },
    methods: {
      // 格式化时间
      formatDate(timestamp){
        if('UTCTime' == this.time){
          return formatUTCTime(timestamp);
        }else{
          return formatTime(timestamp);
        }
      },
      // 生成秘钥
      generateSecretKey(){
        let api = config.baseUrl + '/ga/private/secret/generate';
        this.$http.get(api).then(response => {
            let data = response.data;
            if (data.code == 200) {
                // 生成秘钥成功
                this.$router.push({name: 'enableGA'});
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        }, error => {
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 查询自己
      getMe(){
        if (this.user != null && this.user.token != null) {
            let api = config.baseUrl + '/user/private/getMe';
            this.$http.get(api).then(response => {
            let data = response.data;
            if (data.code == 200) {
                this.$store.dispatch('userHandler', data.result);
            } else {
                this.$store.dispatch('userHandler', []);
            }
           }, error => {
              this.$store.dispatch('userHandler', []);
           });
        }
      },
      // 启用GA
      enableGA(){
        this.generateSecretKey();
      },
      // 关闭GA
      closeGA(){
        this.$router.push({name: 'closeGA'});
      }
    }
  }

</script>

<style>

</style>
