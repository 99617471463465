<template>
    <div>
        <v-container fluid class="grid-list-xl py-0 mt-12" v-if="responsive == 'PC'">
        <v-row justify="center">
            <v-col cols="2">
                <v-list dense>
                    <v-subheader>
                        {{ $t('user.account.account') }}
                        </v-subheader>
                        <v-divider></v-divider>
                    <v-list-item-group>
                        <v-list-item @click="detail='info'">
                            <v-list-item-icon>
                                <v-icon left>mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('user.account.userInfo') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="detail='locations'">
                            <v-list-item-icon>
                                <v-icon left>mdi-map-marker-radius</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('user.account.loginHistory') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col cols="8">
                <user-info v-show="detail=='info'"></user-info>
                <user-locations v-show="detail=='locations'"></user-locations>
            </v-col>
        </v-row>
    </v-container>
    <v-container fluid class="grid-list-xl py-0 mt-12" v-if="responsive == 'mobile' || responsive == 'pad'">
         <user-info v-show="bottomNav=='info'"></user-info>
         <user-locations v-show="bottomNav=='locations'"></user-locations>
    </v-container>
    <v-bottom-navigation v-model="bottomNav" :class="[darkMode?'grey darken-3':'']" horizontal fixed class="mt-4 flex-b text-center"  v-if="responsive == 'mobile'">
      <v-btn color="lightred" text tile x-large value="info" class="mx-5">
        <span>{{ $t('user.account.userInfo') }}</span>
        <v-icon left>mdi-account</v-icon>
      </v-btn>
      <v-btn color="lightred" text tile x-large value="locations">
        <span>{{ $t('user.account.loginHistory') }}</span>
        <v-icon>mdi-map-marker-radius</v-icon>
      </v-btn>
    </v-bottom-navigation>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import UserLocations from '@/components/user/UserLocations'
    import UserInfo from '@/components/user/UserInfo'

    export default {
        data(){
            return{
                detail:"info",
                bottomNav: "info",
            }
        },
        created(){
            this.isLogin();
        },
        mounted(){

        },
        computed: {
            ...mapGetters(['user', 'responsive','darkMode'])
        },
        components: { 
            UserLocations,UserInfo
        },
        watch:{

        },
        methods: {
            // 是否登录
            isLogin(){
                if (!this.user.token) {
                    this.$router.push({name: 'home'});
                }
            },
            
        },
    }
</script>
